@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: inherit radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.ripple:active {
  background-color: rgb(255, 255, 255);
  background-size: 100%;
  transition: background 0s;
}
.active {
  color: #1a9987;
}
.text-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 40px !important;
  height: 40px !important;
  transform: translateY(-50%);
  border-radius: 6px;
  background-color: #609991 !important;
  border: none;
  outline: none;
  color: #fff !important;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
  font-weight: 700 !important;
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

@layer components {
  .active-nav {
    height: auto !important;
  }
}

.ag-theme-alpine {
  --ag-background-color: #ddd;
}
/* change the font style of a single UI component */
.ag-theme-alpine .ag-header {
  background-color: #9dddc4;
}
